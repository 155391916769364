import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";

import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children }) => {
    const navigate = useNavigate();



    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
    // const redirectUri = process.env.REACT_APP_AUTH0_URI;
    let connection = "";


    const currentSubdomain = window.location.hostname.split('.')[0];
    const redirectUri = `https://${currentSubdomain}.actalyst.ai/app/home`;

    if (currentSubdomain === 'bluestar') {
        connection = 'Bluestar';
    } else if (currentSubdomain === 'hindalco') {
        connection = 'Hindalco';
    } else if (currentSubdomain === 'kaboom') {
        connection = 'Actalyst';
    }


    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    // const onRedirectCallback = (appState) => {
    //     history.push(
    //       appState && appState.returnTo ? appState.returnTo : window.location.pathname
    //     );
    //   };

    if (!(domain && clientId && redirectUri && audience)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: audience,
                redirect_uri: redirectUri,
                connection: connection,
                scope: "openid profile email",
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};
