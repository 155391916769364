import { combineReducers } from 'redux';

// // Front
import Layout from './layout/reducer';

// // Calendar
// import calendar from "./calendar/reducer";

// // Authentication
// import forgetPassword from "./auth/forgetpwd/reducer";
// import login from "./auth/login/reducer";
// import profile from "./auth/profile/reducer";
// import account from "./auth/register/reducer";

// Page data
import PageData from './pagedata/reducer';
import Global from './global/reducer';

const rootReducer = combineReducers({
  // public
  Global,
  PageData,
  Layout,
});

export default rootReducer;
