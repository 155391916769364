import {
  CHANGE_STRUCTURED_QUESTION,
  CHANGE_UNSTRUCTURED_QUESTIONS,
  CHANGE_SUMMARY_QUESTION,
  REFRESH_QUESTIONS,
  CHANGE_FILE_KEY,
  CHANGE_FILE_NAME,
  CHANGE_SEARCH_DISABLED,
  CHANGE_FILTER_DATA,
  CHANGE_ORIGINAL_DATA,
  CHANGE_FEATURE_LIST,
  CHANGE_TRAIL_EXPIRED_MESSAGE,
  CHANGE_CONTENT_SEARCH_QUESTION,
  CHANGE_CHAT_QUESTIONS,
  CHANGE_WEBSOCKET,
  CHANGE_WEBSOCKET_UNSTRUCTURED,
  CHANGE_CHAT_QUESTIONS_UNSTRUCTURED,
  CHANGE_GROUPS,
  FILESENSE_APP_DISPLAY_HANDLER,
  CHANGE_APPS_STRUCTURED,
  CHANGE_CURRENT_APP,
  SHOWNOTFICATION,
  CHANGE_NOTFICATION_MESSAGE,
  APP_DISPLAY_HANDLER,
  IS_SIDEBAR_OPEN,
  FILE_SENSE_S3_LOCATION,
  FILE_SENSE_BUCKET_NAME,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  THREADS,
  PROVIDER,
  EDITOR,
  CHANGE_SIDEBAR_VISIBILITY,
  UPDATE_PAGE_COUNTS,
  UPDATE_TEAMS_ADMIN_STATUS,
  SHOW_COMMENTS_WA,
  FA_ANSWER_MODE,
  CHANGE_FILE_UPLOADED,
  CHANGE_COMPANY_DATA_IR,
  CHAT_DROPDOWN_DATA
} from "./actionTypes";
const initialState = {
  pageCounts: {
    myTasks: 0,
    drafts: 0,
    approved: 0,
    submittedForApproval: 0,
    needRevision: 0,
    withdrawn: 0,
    pendingApproval: 0,
    teamApproved: 0,
    sentForApproval: 0,
    orgApproved: 0,
    orgSubmittedForApproval: 0,
    orgNeedRevision: 0,
  },
  isTeamsEnabled: false,
  isAdminEnabled: false,
 
};

const INIT_STATE = {
  structuredquestion: "",
  unstructuredquestion: [],
  summaryquestions: [],
  fileKey: "",
  filename: "",
  isSearchDisabled: true,
  filterData: [],
  originalData: [],
  contentSearchQuestion: "",
  featureList: [],
  trailExpiredMessage: "",
  chatQuestions: [],
  websocket: null,
  websocketUnstructured: null,
  chatQuestionsUnstructured: [],
  groups: [],
  filesenseAppDisplay: "",
  appsStructured: [],
  currentApp: {},
  show: false,
  notficationMessage: "",
  appDisplay: "",
  isSideBarOpen: false,
  fileSenseS3Location: "",
  fileSenseBucketName: "",
  snackbar: {
    open: false,
    message: "",
    action: null,
  },
  threads: [],
  provider: null,
  editor: null,
  sidebarVisble: true,
  showComments: true,
  fsAnswerMode: false,
  fileuploaded: null,
  companyIRData:{},
  chatDropdownData:{}
};

const PageData = (state = INIT_STATE, action) => {
  // console.log("hello")
  switch (action.type) {
    case APP_DISPLAY_HANDLER:
      return {
        ...state,
        appDisplay: action.payload,
      };
    case FILESENSE_APP_DISPLAY_HANDLER:
      return {
        ...state,
        filesenseAppDisplay: action.payload,
      };
    case CHANGE_STRUCTURED_QUESTION:
      return {
        ...state,
        structuredquestion: action.payload,
      };
    case CHANGE_UNSTRUCTURED_QUESTIONS:
      return {
        ...state,
        unstructuredquestion: [...state.unstructuredquestion, action.payload],
      };
    case CHANGE_SUMMARY_QUESTION:
      return {
        ...state,
        summaryquestions: [...state.summaryquestions, action.payload],
      };
    case REFRESH_QUESTIONS:
      return {
        ...state,
        structuredquestion: "",
        unstructuredquestion: [],
        summaryquestions: [],
        contentSearchQuestion: "",
      };
    case CHANGE_FILE_KEY:
      return {
        ...state,
        fileKey: action.payload,
      };
    case CHANGE_FILE_NAME:
      return {
        ...state,
        filename: action.payload,
      };
    case CHANGE_SEARCH_DISABLED:
      return {
        ...state,
        isSearchDisabled: action.payload,
      };
    case CHANGE_FILTER_DATA:
      return {
        ...state,
        filterData: action.payload,
      };
    case CHANGE_ORIGINAL_DATA:
      return {
        ...state,
        originalData: action.payload,
      };
    case CHANGE_CONTENT_SEARCH_QUESTION: {
      // console.log("#payload#",action.payload)

      return {
        ...state,
        contentSearchQuestion: action.payload,
      };
    }
    case CHANGE_FEATURE_LIST:
      return {
        ...state,
        featureList: action.payload,
      };
    case CHANGE_TRAIL_EXPIRED_MESSAGE:
      return {
        ...state,
        trailExpiredMessage: action.payload,
      };
    case CHANGE_CHAT_QUESTIONS:
      return {
        ...state,
        chatQuestions: [...state.chatQuestions, action.payload],
      };

    case CHANGE_WEBSOCKET:
      return {
        ...state,
        websocket: action.payload,
      };
    case CHANGE_WEBSOCKET_UNSTRUCTURED:
      return {
        ...state,
        websocketUnstructured: action.payload,
      };
    case CHANGE_CHAT_QUESTIONS_UNSTRUCTURED:
      return {
        ...state,
        chatQuestionsUnstructured: action.payload,
      };
    case CHANGE_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case CHANGE_APPS_STRUCTURED:
      return {
        ...state,
        appsStructured: action.payload,
      };
    case CHANGE_CURRENT_APP:
      return {
        ...state,
        currentApp: action.payload,
      };
    case SHOWNOTFICATION:
      return {
        ...state,
        show: action.payload,
      };
    case CHANGE_NOTFICATION_MESSAGE:
      return {
        ...state,
        notficationMessage: action.payload,
      };
    case IS_SIDEBAR_OPEN:
      return {
        ...state,
        isSideBarOpen: action.payload,
      };
    case FILE_SENSE_S3_LOCATION:
      return {
        ...state,
        fileSenseS3Location: action.payload,
      };
    case FILE_SENSE_BUCKET_NAME:
      return {
        ...state,
        fileSenseBucketName: action.payload,
      };

    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: {
          open: true,
          message: action.payload.message,
          action: action.payload.action,
        },
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
        },
      };
    case PROVIDER:
      return {
        ...state,
        provider: action.payload,
      };
    case THREADS:
      return {
        ...state,
        threads: action.payload,
      };
    case EDITOR:
      return {
        ...state,
        editor: action.payload,
      };
    case CHANGE_SIDEBAR_VISIBILITY:
      return {
        ...state,
        sidebarVisble: action.payload,
      };
    case UPDATE_PAGE_COUNTS:
      return {
        ...state,
        pageCounts: action.payload,
      };
    case UPDATE_TEAMS_ADMIN_STATUS:
      return {
        ...state,
        isTeamsEnabled: action.payload.isTeamsEnabled,
        isAdminEnabled: action.payload.isAdminEnabled,
      };
    case SHOW_COMMENTS_WA:
      return {
        ...state,
        showComments: action.payload
      }
    case FA_ANSWER_MODE:
      return {
        ...state,
        fsAnswerMode: action.payload
      }

    case CHANGE_FILE_UPLOADED:
      return{
        ...state,
        fileuploaded:action.payload
      }
      case CHANGE_COMPANY_DATA_IR:
      return{
        ...state,
        companyIRData:action.payload
      }
      case CHAT_DROPDOWN_DATA:
        return{
          ...state,
          chatDropdownData:action.payload
        }
    default:
      return state;
  }
};

export default PageData;
