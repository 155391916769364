import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

function ErrorFallback({ error }) {
  const isChunkError =
    error?.message?.includes('ChunkLoadError') ||
    /Loading chunk [\d]+ failed/.test(error?.message) ||
    error?.message?.includes("Unexpected token '<'");

  React.useEffect(() => {
    if (isChunkError) {
      const hasReloaded = localStorage.getItem('chunkReloaded');
      if (!hasReloaded) {
        console.warn('Detected chunk load failure or syntax error. Reloading the page...');
        localStorage.setItem('chunkReloaded', 'true');
        window.location.reload();
      }
    }
  }, [isChunkError]);

  if (isChunkError) {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <h2>A new version of this app is available.</h2>
        <p>Please refresh your browser to continue.</p>
        <button
          onClick={() => {
            localStorage.removeItem('chunkReloaded');
            window.location.reload();
          }}
        >
          Refresh
        </button>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2>Oops, something went wrong.</h2>
      <p>Please try again later or contact support.</p>
    </div>
  );
}

const AppErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        console.error('Caught an error:', error, info);
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default AppErrorBoundary;
