import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { createBrowserHistory } from 'history';

import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const ENVIRONMENT = process.env.REACT_APP_SENTRY_NODE_ENV;
const history = createBrowserHistory();

const initSentry = () => {
  // if (ENVIRONMENT === 'stage') {
  //   console.log('Sentry is disabled in production and stage environment');
  //   return;
  // }

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
        history,
      }),
      Sentry.replayIntegration(),
    ],

    enableTracing: true,

    tracesSampleRate: ENVIRONMENT === 'production' ? 0.2 : 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,

    tracePropagationTargets: ['localhost', process.env.REACT_APP_AUTH0_URL],

    environment: ENVIRONMENT,

    beforeSend: (event) => {
      // Don't send events in development
      //   if (ENVIRONMENT === 'development') {
      //     return null;
      //   }
      return event;
    },

    markAttribute: 'data-sentry-mark',

    trackComponents: true,

    // debug: ENVIRONMENT === 'development',
    debug: false,
  });
};

export const captureException = (error, context = {}) => {
  Sentry.withScope((scope) => {
    Object.keys(context).forEach((key) => {
      scope.setExtra(key, context[key]);
    });
    Sentry.captureException(error);
  });
};

export const captureMessage = (message, level = 'info') => {
  Sentry.captureMessage(message, level);
};

export const setUserContext = (user) => {
  Sentry.setUser(user);
};

export default initSentry;
