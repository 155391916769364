import React, { useEffect, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { NotificationProvider } from './Pages/Filesense/AdvancedFilesense/Components/NotificationProvider';
import './assets/scss/theme.scss'; // Ensure this is optimized as per recommendations
// import fakeBackend from './helpers/AuthType/fakeBackend';
// import 'antd/dist/reset.css';

// Lazy-loaded components
const Routes = lazy(() => import('./Routes/index'));
const CustomSnackbar = lazy(() => import('./components/Common/CustomSnackbar'));

function App() {
  const location = useLocation();
  const newrelic = 'https://actstaticfiles.s3.amazonaws.com/newrelic.js';

  useEffect(() => {
    // Activate fake backend after initial render
    // fakeBackend();

    // Store initial URL in session storage
    const storedUrl = sessionStorage.getItem('initialUrl');
    if (
      location.pathname !== storedUrl &&
      location.pathname !== '/' &&
      !storedUrl?.includes('_used')
    ) {
      const currentUrl = location.pathname;
      sessionStorage.setItem('initialUrl', currentUrl);
    }
    if (!storedUrl) {
      const currentUrl = location.pathname;
      sessionStorage.setItem('initialUrl', currentUrl);
      console.log(currentUrl, 'initial url set');
    }
  }, []); // Empty dependency array ensures this runs only once

  const shouldReturnNewrelic = () => {
    if (process.env.REACT_APP_NULERIC_ENABLED === 'true') {
      return (
        <Helmet>
          <script src={newrelic} async defer></script>
        </Helmet>
      );
    }
    return null;
  };

  return (

    <React.Fragment>
      <NotificationProvider>
        {shouldReturnNewrelic()}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes />
          <CustomSnackbar />
        </Suspense>
      </NotificationProvider>
    </React.Fragment>
  );
}

export default App;
