import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState, useCallback } from 'react';

const FRESHCHAT_API_BASE = process.env.REACT_APP_AUTH0_URL;
const FRESHCHAT_TOKEN = process.env.REACT_APP_FRESHCHAT_TOKEN;
const FRESHCHAT_HOST =
  process.env.REACT_APP_FRESHCHAT_HOST || 'https://wchat.freshchat.com';

const FreshchatWidget = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isInitialized, setIsInitialized] = useState(false);

  const getStoredRestoreId = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${FRESHCHAT_API_BASE}/fresh_chat_sdk/session/${user?.sub}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      return data?.session?.restore_id || null;
    } catch (error) {
      console.error('Failed to get restore ID:', error);
      return null;
    }
  }, [user?.sub, getAccessTokenSilently]);

  const storeRestoreId = useCallback(
    async (restoreId) => {
      try {
        const token = await getAccessTokenSilently();
        await fetch(`${FRESHCHAT_API_BASE}/fresh_chat_sdk/session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_id: user?.sub,
            restore_id: restoreId,
          }),
        });
      } catch (error) {
        console.error('Failed to store restore ID:', error);
      }
    },
    [user?.sub, getAccessTokenSilently]
  );

  const initializeFreshchat = useCallback(async () => {
    try {
      const existingRestoreId = await getStoredRestoreId();

      // Set Freshchat settings before loading script
      window.fcSettings = {
        token: FRESHCHAT_TOKEN,
        host: FRESHCHAT_HOST,
        externalId: user.sub,
        restoreId: existingRestoreId,
        firstName: user.nickname || user.email,
        email: user.email,
      };

      // Remove any existing freshchat script
      const existingScript = document.getElementById('freshchat-js-sdk');
      if (existingScript) {
        existingScript.remove();
      }

      // Create new script
      const script = document.createElement('script');
      script.id = 'freshchat-js-sdk';
      script.async = true;
      script.src = `${FRESHCHAT_HOST}/js/widget.js`;

      // Handle success
      script.onload = () => {
        if (!window.fcWidget) {
          console.error(
            'Freshchat script loaded but window.fcWidget is not defined. Possibly blocked.'
          );
          return;
        }

        // Register event handlers
        window.fcWidget.on('user:created', (resp) => {
          if (resp?.data?.restoreId) {
            storeRestoreId(resp.data.restoreId);
          }
        });

        window.fcWidget.on('widget:initialized', () => {
          setIsInitialized(true);
        });

        window.fcWidget.user.get((resp) => {
          if (resp?.data?.restoreId && !existingRestoreId) {
            storeRestoreId(resp.data.restoreId);
          }
        });

        window.fcWidget.on('conversation:status-changed', (resp) => {
          if (resp?.data?.status === 'RESOLVED') {
            console.log('Conversation resolved.');
          }
        });
      };

      // Handle load error
      script.onerror = (err) => {
        console.error('Failed to load Freshchat script:', err);
      };

      // Append the script
      document.head.appendChild(script);
    } catch (error) {
      console.error('Freshchat initialization error:', error);
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;

    initializeFreshchat();

    return () => {
      if (window.fcWidget) {
        window.fcWidget.destroy();
        setIsInitialized(false);
      }
    };
  }, [user, initializeFreshchat]);

  return null;
};

export default FreshchatWidget;
